import { useEffect } from 'react';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { axiosInstance } from 'App';
import makePost from 'helpers/makePost';
import { useParams, useHistory } from 'react-router-dom';

const JobsNew = () => {
    const { quoteID, itemID, revision } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchQuoteDetails = async () => {

            const result = await axiosInstance.get(`jobs?QuoteID=${parseInt(quoteID)}&ItemID=${parseInt(itemID)}&Revision=${revision}&pageNum=1&pageSize=10`);

           if (result.data.length) {
                dispatch(addFeedback('Job already exists with the same Quote ID, Item ID & Revision. Redirecting back to previous page.', 'error'));
                history.goBack(1)
            } else {
                const response = await makePost({
                    url: `/jobs`,
                    data: { 
                        quoteID: parseInt(quoteID),
                        itemID: parseInt(itemID),
                        revision
                    },
                });
    
                if(response.data.id) {
                    history.push(`/jobs/edit/${response.data.id}`);
                } else {
                    dispatch(addFeedback('Error creating new job, please go back and try again.', 'error'));
                }
            }
        };

        fetchQuoteDetails();
    }, []);

    return <></>;
};

export default JobsNew;
