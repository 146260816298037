import { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import makePost from 'helpers/makePost';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FolderIcon from '@material-ui/icons/Folder';
import PublishIcon from '@material-ui/icons/Publish';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';

import axios from 'axios';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Dropzone from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
    nested: {
      paddingLeft: theme.spacing(9),
    },
    dropzone: {
        textAlign: 'center',
        padding: '15px',
        border: '3px dashed #eeeeee',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        cursor: 'pointer',
        marginBottom: '20px',
    },
    selectedFileWrapper: {
        textAlign: 'center',
    },
    selectedFile: {
        color: '#000',
        fontWeight: 'bold'
    }
  }));


const File = ({url, files, setRefresh }) => {
    const [showArchived, setShowArchived] = useState(false);
    const [open, setOpen] = useState(null);

    const [selectedFiles, setSelectedFiles] = useState({});

    const classes = useStyles();
    
    const folders = [
        {
            files: files.filter(({ tags } )=> tags.includes('Suppliers')),
            label: 'Suppliers'
        },
        {
            files: files.filter(({ tags } )=> tags.includes('Engineering')),
            label: 'Engineering'
        },
        {
            files: files.filter(({ tags } )=> tags.includes('Client Correspondence')),
            label: 'Client Correspondence'
        },
        {
            files: files.filter(({ tags } )=> tags.includes('Quality Documents')),
            label: 'Quality Documents'
        },
        {
            files: files.filter(({ tags } )=> tags.includes('Purchasing')),
            label: 'Purchasing'
        },
        {
            files: files.filter(({ tags } )=> tags.includes('Dispatch')),
            label: 'Dispatch'
        },
        {
            files: files.filter(({ tags } )=> tags.includes('Photos')),
            label: 'Photos'
        },
        {
            files: files.filter(({ tags } )=> tags.includes('Quote')),
            label: 'Quote'
        },
        {
            files: files.filter(({ tags } )=> tags.includes('Commercials')),
            label: 'Commercials'
        },
        {
            files: files.filter(({ tags } )=> tags.some(t => t.toLowerCase() == "centrix")),
            label: 'Centrix'
        }
    ]
    
    const onFileAdd = async (tag) => {
        const file = selectedFiles[tag];
       
        const response = await makePost({
            url,
            data: {
                description: file.name,
                tags: [
                    tag
                ]
            },
        });

        const options = {
          headers: {
            'x-amz-meta-file-id': response.data.id,
            'Content-Disposition': `attachment; filename="${file.name}"`
          }
        };
  
        const responseS3 = await axios.put(response.data.presignedUrl, file, options);

        if (responseS3.status) {
            const newFiles = selectedFiles;
            delete newFiles[tag];
            setSelectedFiles(newFiles);
            setRefresh(true)
            setTimeout(() => setRefresh(true), 2000);
        }
    };
    
    const onFileArchive = async (event, {id, ...file}, fileStatus) => {
        const response = await makePost({
            url: `${url}/${id}`,
            data: {
                ...file,
                fileStatus,
            },
        });

        if (response.status) {
            setRefresh(true)
            setTimeout(setRefresh(true), 2000);
        }
        setOpen(false);
    };


    const handleClickOpen = (event, id) => {
        setOpen(id);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const onDrop = (file, label) => {
        if (file.length > 0) {
            setSelectedFiles({...selectedFiles, [label]: file[0]});
        }
    }

    return (
        <form >  
            <div style={{width: '100%'}}>
                <List dense={true}>

                    {folders.map(({
                        files,
                        label
                    }) => 
                        <>
                            <ListItem>
                                <ListItemIcon>
                                    <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary={label} />
                            </ListItem>

                            <List component="div" disablePadding dense={true}>
                                {files.map((file, i) => 
                                (!showArchived && file.fileStatus === 'Archived' ? null : 
                                    <ListItem key={i} button={file.presignedUrl ? true : false} component={file.presignedUrl ? "a" : "span"} className={classes.nested} href={file.presignedUrl} download="renamed.txt">
                                        <ListItemText primary={file.presignedUrl ? file.description : 'File not available - '+file.description} secondary={`Uploaded by ${file.createdUserName} on ${new Date(file.updatedAt).toLocaleDateString('en-AU')} - Status: ${file.fileStatus}`}/>
                                        {file.fileStatus !== 'Archived' ? <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="archive" onClick={event => handleClickOpen(event, file.id)}>
                                                <ArchiveIcon />
                                            </IconButton>
                                                <Dialog
                                                    open={open === file.id}
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to Archive this file."}</DialogTitle>
                                                    <DialogActions>
                                                    <Button onClick={handleClose} color="primary">
                                                        No
                                                    </Button>
                                                    <Button onClick={event => onFileArchive(event, file, 'Archived')}>
                                                        Yes
                                                    </Button>
                                                    </DialogActions>
                                                </Dialog>
                                        </ListItemSecondaryAction> : <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="unarchive" onClick={event => onFileArchive(event, file, 'Available')}>
                                                <UnarchiveIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>}
                                        
                                    </ListItem>)
                                )}
                                <ListItem className={classes.nested}>
                                    <Dropzone onDrop={file => onDrop(file, label)} multiple={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <Grid container justify="space-between">
                                                <div className={classes.dropzone} {...getRootProps({  })}>
                                                    <input {...getInputProps()} />
                                                    {selectedFiles[label]?.name ? (
                                                    <div className="selected-file">
                                                        {selectedFiles[label]?.name}
                                                    </div>
                                                    ) : (
                                                    'Drag and drop file here, or click to select file'
                                                    )}
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    color='primary'
                                                    disabled={!selectedFiles[label]}
                                                    onClick={() => onFileAdd(label)}
                                                    startIcon={<PublishIcon />}
                                                    style={{ height: '35px', width: '100px', marginTop: '11px', marginLeft: '10px'}}
                                                    >
                                                    Upload
                                                </Button>
                                            </Grid>
                                        )}
                                    </Dropzone>
                                </ListItem>
                            </List>
                        </>
                    )}
                </List>
            </div>
            <FormControlLabel 
                control={
                    <Checkbox 
                        checked={showArchived}
                        onChange={(event) => setShowArchived(event.target.checked)}
                    />
                } 
                label="Show Archived Files"
                style={{paddingLeft: '18px'}}
            />
        </form>
    );
};

export default File;
